<template>
  <div class="order-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>

<script>
  let validRoomNo = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
      let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
      if (!reg.test(value)) {
        callback(new Error("房号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        orderType: "",
        status: "",
        name: "",
        phone: "",
        opt: {
          title: "订单列表",
          search: [{
              key: "apartmentId",
              label: "房源",
              type: "select",
              opt: {
                list: []
              }
            },
            {
              key: "roomNo",
              label: "房号",
              maxlength: 24,
              rules: [{
                validator: validRoomNo,
                trigger: ['blur']
              }]
            },
            {
              key: "orderType",
              label: "租赁模式",
              type: "select-no-filterable",
              value: "0",
              opt: {
                list: [{
                    label: "长租",
                    value: "0"
                  },
                  {
                    label: "短租",
                    value: "1"
                  },
                  {
                    label: "钟点房",
                    value: "3"
                  }
                ]
              }
            },
            {
              key: "status",
              label: "订单状态",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "未支付",
                    value: "1"
                  },
                  {
                    label: "待支付",
                    value: "2"
                  },
                  {
                    label: "已支付",
                    value: "3"
                  },
                  {
                    label: "支付失败",
                    value: "4"
                  },
                  {
                    label: "订单取消",
                    value: "5"
                  },
                  {
                    label: "已失效",
                    value: "6"
                  },
                  {
                    label: "取消预订",
                    value: "7"
                  },
                ]
              }
            },
            {
              key: "name",
              label: "姓名",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            //   {
            //     key: "phone",
            //     label: "手机号"
            //   },
            {
              key: "passTime",
              label: "下单时间",
              type: "time-range",
              defaultValue: ["00:00:00", "23:59:59"]
            }],
          columns: [{
              label: "订单号",
              key: "orderNo",
              on: row => {
                _this.$router.push("/main/order/detail/show/" + row.orderNo);
              }
            },
            {
              label: "租赁模式",
              key: "orderTypeStr",
              align: "center"
            },
            {
              label: "下单日期",
              key: "payRentalTerm"
            },
            {
              label: "订单名称",
              key: "orderName"
            },
            {
              label: "总金额",
              key: "orderAmount",
              width: 120
            },
            {
              label: "姓名",
              key: "userName"
            },
            {
              label: "手机号",
              key: "phoneNumber"
            },
            {
              label: "订单状态",
              key: "payState",
              width: 120
            }
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/finance-service/leaseOrder/exportOrder",
            listName: "订单列表"
          }],
        }
      };
    },
    created() {
      console.log("order-list created!!");

    },
    activated() {
      this.getApartmentList();
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNumber: opt.skip,
          pageSize: opt.limit,
          phoneNumber: opt.searchForm.phone ? opt.searchForm.phone : null,
          payState: opt.searchForm.status ? opt.searchForm.status : null,
          userName: opt.searchForm.name ? opt.searchForm.name : null,
          orderNo: opt.searchForm.code ? opt.searchForm.code : null,
          roomNo: opt.searchForm.roomNo ? opt.searchForm.roomNo : null,
          isWeb: 1,
          apartmentId: opt.searchForm.apartmentId ?
            opt.searchForm.apartmentId :
            null,
          orderType: opt.searchForm.orderType ? opt.searchForm.orderType : "0",
          startDate: opt.searchForm["passTime"] ?
            this.format(opt.searchForm["passTime"][0]) :
            null,
          endDate: opt.searchForm["passTime"] ?
            this.format(opt.searchForm["passTime"][1]) :
            null
        };
        this.post("/finance-service/leaseOrder/searchPageOrder", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            if(item.orderType == 1 || item.orderType == 3) {
              item.payRentalTerm = this.format(item.createdDate,1);
            }else {
              item.payRentalTerm = item.payRentalTerm ? item.payRentalTerm : "";
            }
            item.orderTypeStr = ["长租", "短租",'',"钟点房"][item.orderType];
            item.phoneNumber = item.phoneNumber ?
              item.phoneNumber.substring(0, 3) +
              "****" +
              item.phoneNumber.substring(7, item.phoneNumber.length) :
              "";
            item.payState = [
              "未支付",
              "待支付",
              "已支付",
              "支付失败",
              "订单取消",
              "已失效",
              "取消预订"
            ][item.payState - 1];
            item.orderAmount = "￥" + item.orderAmount;
          }
          opt.cb(res.data);
        });
      },

      //获取房源列表
      getApartmentList() {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null :
            this.cache.getLS("userInfo")["userId"]
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let returnData = {};
            res.forEach(data => {
              returnData[data.id] = data.name;
            });
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.opt.search[0]["opt"]["list"] = res;
          }
        );
      }
    }
  };
</script>